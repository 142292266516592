import { FC, FormEvent, Fragment, useState } from 'react'

import { Title, Button } from '~/components'
import { InputField } from '~/screens/auth/components'
import { IGenre } from '~/types'

import { GenresPicker } from '../../components'
import { styles } from './styles'

interface GenresProps {
  tags: IGenre[]
  selectedTags: string[]
  updateProgress: () => void
  onTagSearch: (e: FormEvent<HTMLInputElement>) => void
  onTagSelect: (tag: IGenre) => void
}

export const GenresSection: FC<GenresProps> = ({
  updateProgress,
  tags,
  selectedTags,
  onTagSearch,
  onTagSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    setSearchQuery(value)
    onTagSearch(e)
  }

  return (
    <Fragment>
      <Title extendCss={styles.title}>What genres do you like</Title>
      <InputField
        type="text"
        id="genre"
        name="genre"
        placeholder="Search genres"
        required={false}
        extendCss={styles.input}
        onChange={handleChange}
        value={searchQuery}
        data-testid="input_tags"
      />
      <GenresPicker
        tags={tags}
        selectedTags={selectedTags}
        onTagSelect={onTagSelect}
        searchQuery={searchQuery}
      />
      <Button
        isDisabled={!selectedTags.length}
        extendCss={styles.button}
        onClick={updateProgress}
        data-testid="tags_button"
      >
        Next
      </Button>
    </Fragment>
  )
}
