import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import * as qs from 'querystring'

import {
  validateName,
  validateEmail,
  validatePassword,
} from '~/screens/auth/utils/validators'
import { firebaseApp } from '~/firebase'
import { auth } from '~/screens/auth/firebase/firebase-auth'
import { RequestApiContext, AuthContext } from '~/context'
import { Background } from '~/screens/auth/components'
import {
  Analytics,
  AnalyticsEvent,
  LocalStorageKeys,
  localStorageService,
} from '~/services'
import { tags as AllTags } from '~/config'
import { IGenre, ServerUser } from '~/types'

import { NameSection, GenresSection, FormSection } from './sections'
import { ProgressBar } from './components'
import { styles } from './styles'

// need to manage progress of register screen
enum Sections {
  Name = 1,
  Tags = 2,
  Creds = 3,
}

interface User {
  name: string
  email: string
  password: string
  selectedTags: string[]
  termsAccepted: boolean
  emailsAccepted: boolean
}

export const RegisterScreen: FC<RouteComponentProps> = ({ location }) => {
  const [section, setSection] = useState<Sections>(Sections.Name)
  const [displayedTags, setDisplayedTags] = useState<IGenre[]>(AllTags)
  const [referralCode, setReferralCode] = useState<string>('')
  const [user, setUser] = useState<User>({
    name: '',
    email: '',
    password: '',
    selectedTags: [],
    termsAccepted: false,
    emailsAccepted: true,
  })
  const [errors, setErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const requestApiContext = useContext(RequestApiContext)
  const authContext = useContext(AuthContext)

  useEffect(() => {
    // Delete char "?"
    const query = location!.search.slice(1)

    // Parse querystring
    let { rf } = qs.parse(query) as { rf: string }

    // if not in query, check localstorage
    // this is needed to not loose referral code when navigating between routes
    if (!rf) {
      rf = localStorageService.getValue(LocalStorageKeys.REFERRAL_CODE) || ''
    }

    // Set the referral code to state
    if (rf) {
      setReferralCode(rf)
      localStorageService.setValue(LocalStorageKeys.REFERRAL_CODE, rf)
    }
  }, [])

  // clear info about reset password in local storage
  useEffect(() => {
    localStorageService.setValue(LocalStorageKeys.FIRST_RESET_REQUEST, '')
    localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, '')
    localStorageService.setValue(LocalStorageKeys.RESET_PASSWORD_USER_EMAIL, '')
  }, [])

  const handleTagSearch = (e: FormEvent<HTMLInputElement>): void => {
    const searchQuery = e.currentTarget.value

    // search query on the first position of tag label
    const displayedTags = AllTags.filter(
      (tag) => tag.label.toLowerCase().indexOf(searchQuery.toLowerCase()) === 0,
    )

    setDisplayedTags(displayedTags)
  }

  const handleTagSelect = (selectedTag: IGenre): void => {
    // genres that do not equal selected genre
    const uniqueTags = user.selectedTags.filter((tag) => {
      return tag !== selectedTag.value
    })

    /* if we do not have this selected genre
     * and selected genres number less than 3 add selected genre to arr
     * else - remove this genre from arr
     */
    if (uniqueTags.length === user.selectedTags.length && user.selectedTags.length < 3) {
      setUser((prev) => ({ ...prev, selectedTags: [...uniqueTags, selectedTag.value] }))
    } else {
      setUser((prev) => ({ ...prev, selectedTags: uniqueTags }))
    }
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget
    setUser((prev) => ({ ...prev, [name]: value }))

    // reset errors when user have error and then start typing again
    setErrors([])
  }

  const updateProgressNameSection = (): void => {
    const error: string | null = validateName(user.name)
    if (error) {
      setErrors((prev) => [...prev, error])
    } else {
      setSection(Sections.Tags)
    }
  }

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const emailError: string | null = validateEmail(user.email)
    const passwordError: string | null = validatePassword(user.password)

    if (emailError) setErrors((prev) => [...prev, emailError])
    if (passwordError) setErrors((prev) => [...prev, passwordError])

    // if email and password valid - start registration
    if (!emailError && !passwordError) {
      setIsLoading(true)

      // register firebase user
      const authErr = await auth.signUp(user.email, user.password)

      if (authErr) {
        setErrors((prev) => [...prev, authErr])
        setIsLoading(false)
      } else {
        // get ID Token firebase user
        const idToken = await firebaseApp.auth().currentUser?.getIdToken()

        try {
          // get auth token from API request
          const apiResponse = await requestApiContext.requestApi<{
            authToken: string
            user: ServerUser
          }>({
            url: '/auth/register/fan',
            options: {
              method: 'POST',
              body: JSON.stringify({
                idToken,
                user: {
                  name: user.name,
                  tags: user.selectedTags,
                },
                referralCode,
              }),
            },
          })
          // set token to the context
          authContext.setAuthState({ token: apiResponse.authToken, emailVerified: false })

          // redirect to confirm password screen
          Analytics.logEvent(AnalyticsEvent.RegisterSuccess, { referralCode, ...user })
          navigate('/auth/confirm')
        } catch (error) {
          // expected error
          if (error === 'failed to parse request body') {
            console.debug('expected api error', error)
            setErrors((prev) => [...prev, 'Email or password are invalid.'])
          } else {
            // unexpected errors
            console.error('unexpected api error', error)
            setErrors((prev) => [...prev, error.message])
          }

          // delete Firebase user if API request for registration failed
          firebaseApp.auth().currentUser?.delete()
          Analytics.logEvent(AnalyticsEvent.RegisterFailure, { ...user, error })
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const renderComponent = (progress: Sections) => {
    switch (progress) {
      case Sections.Name:
        return (
          <NameSection
            updateProgress={updateProgressNameSection}
            onInputChange={handleInputChange}
            name={user.name}
            errors={errors}
          />
        )
      case Sections.Tags:
        return (
          <GenresSection
            updateProgress={() => setSection(Sections.Creds)}
            tags={displayedTags}
            selectedTags={user.selectedTags}
            onTagSearch={handleTagSearch}
            onTagSelect={handleTagSelect}
          />
        )
      case Sections.Creds:
        return (
          <FormSection
            email={user.email}
            password={user.password}
            onInputChange={handleInputChange}
            errors={errors}
            onFormSubmit={handleSubmitForm}
            isLoading={isLoading}
            termsAccepted={user.termsAccepted}
            setTermsAccepted={(termsAccepted) => setUser({ ...user, termsAccepted })}
            emailsAccepted={user.emailsAccepted}
            setEmailsAccepted={(emailsAccepted) => setUser({ ...user, emailsAccepted })}
          />
        )
      default:
        return <div>Something went wrong...</div>
    }
  }

  return (
    <div css={[styles.flexContainer, styles.register]}>
      <Background />
      <div css={[styles.flexContainer, styles.container]}>{renderComponent(section)}</div>
      <ProgressBar progress={section} />
    </div>
  )
}
