import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  background: css`
    height: calc(100vh + 242px);
    width: calc(100vh + 242px);
    position: fixed;
    top: -121px;
    background-color: ${Colors.Light6};
    border-radius: 50%;
    z-index: -1;

    /* hide background on <=992px, 
     * because at this width it no longer fits normally in the screen
     */
    @media (max-width: 992px) {
      display: none;
    }
  `,
}
