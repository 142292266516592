import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  tag: {
    base: css`
      display: inline-block;
      font-size: 16px;
      max-height: 36px;
      line-height: 20px;
      padding: 7px 20px;
      border-radius: 30px;
      border: 1px solid ${Colors.Dark1};
      background-color: ${Colors.Light2};
      color: ${Colors.Dark1};
      margin: 4px;
      cursor: pointer;

      @media (max-width: 480px) {
        font-size: 12px;
        line-height: 15px;
        margin: 2px;
      }
    `,

    active: css`
      background-color: ${Colors.Dark1};
      color: ${Colors.Light2};
    `,
  },
}
