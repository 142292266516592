import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  form: css`
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  title: css`
    margin-bottom: 42px;

    @media (max-width: 480px) {
      margin-bottom: 51px;
    }
  `,

  input: css`
    margin-top: 46px;

    @media (max-width: 480px) {
      margin-top: 30px;
    }
  `,

  button: css`
    margin-top: 46px;

    @media (max-width: 480px) {
      margin-top: 37px;
    }
  `,

  error: css`
    font-size: 14px;
    margin-top: 10px;
    align-self: flex-start;
    color: ${Colors.Red};

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,

  checkboxes: css`
    margin-top: 46px;
  `,
}
