import { FC, FormEvent, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Title, Button } from '~/components'
import { Background, InputField } from '~/screens/auth/components'
import { validateEmail } from '~/screens/auth/utils/validators'
import { auth } from '~/screens/auth/firebase/firebase-auth'
import {
  Analytics,
  AnalyticsEvent,
  LocalStorageKeys,
  localStorageService,
} from '~/services'

import { styles } from './styles'

export const ResetPasswordScreen: FC<RouteComponentProps> = () => {
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [screen, setScreen] = useState<'email' | 'reset'>('email')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [seconds, setSeconds] = useState<number>(60)

  useEffect(() => {
    // first reset request
    const firstResetRequest = Boolean(
      localStorageService.getValue(LocalStorageKeys.FIRST_RESET_REQUEST),
    )

    // email
    const resetPasswordUserEmail = localStorageService.getValue(
      LocalStorageKeys.RESET_PASSWORD_USER_EMAIL,
    )

    // if we already have first request show 'reset' screen
    if (firstResetRequest) setScreen('reset')

    // set email to state from local storage if have it
    if (resetPasswordUserEmail) setEmail(resetPasswordUserEmail)
  }, [])

  // when user go to reset screen automatically send email
  useEffect(() => {
    // func for making first request
    const makeFirstRequest = async () => {
      setSeconds(60)
      const resetError = await sendResetPasswordEmail()

      // if no firebase error - start intervals
      if (!resetError) {
        startIntervals(60)
      }
    }

    if (screen === 'reset') {
      // first reset request
      const isFirstResetRequest = Boolean(
        localStorageService.getValue(LocalStorageKeys.FIRST_RESET_REQUEST),
      )

      // time of last reset in ms
      const lastResetTime: string | undefined = localStorageService.getValue(
        LocalStorageKeys.LAST_RESET_TIME,
      )

      // when do not have first time request after changing screen to 'reset'
      if (!isFirstResetRequest) {
        makeFirstRequest()
      }
      // when reopen page - we have last reset time, but first reset request already is
      else if (lastResetTime) {
        // time now in ms
        const timeNow: number = Date.now()

        /* difference between the current time and the time
         * of the last reset
         */
        const timeDifference: number = Math.floor(
          (timeNow - Number(lastResetTime)) / 1000,
        )

        /* if the time that has passed since the last
         * reset is more than 60 seconds, set seconds to 0
         * and in the local storage set an empty value
         */
        if (timeDifference > 60) {
          setSeconds(0)
          localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, '')
        }
        // else set the remaining seconds and intervals
        else {
          setSeconds(60 - timeDifference)
          startIntervals(60 - timeDifference)
        }
      }

      // if it is first reset request
      if (!isFirstResetRequest) {
        // set first request to local storage
        localStorageService.setValue(
          LocalStorageKeys.FIRST_RESET_REQUEST,
          String(Date.now()),
        )

        // set user email to local storage
        localStorageService.setValue(LocalStorageKeys.RESET_PASSWORD_USER_EMAIL, email)
      }
    }
  }, [screen])

  useEffect(() => {
    // every time when seconds changed check if it equal to 0 - set button to active
    if (seconds === 0) setIsButtonDisabled(false)
  }, [seconds])

  const startIntervals = (clearTime: number) => {
    setIsLoading(false)
    setIsButtonDisabled(true)

    // decrease seconds every 1s
    const intervalDecreaseSeconds = setInterval(
      () => setSeconds((seconds) => seconds - 1),
      1000,
    )

    /* clear intervals after clearTime and
     * set empty value of last reset time to local storage
     */
    setTimeout(() => {
      clearInterval(intervalDecreaseSeconds)
      localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, '')
    }, clearTime * 1000)
  }

  const sendResetPasswordEmail = async () => {
    setIsLoading(true)
    // reset password with firebase
    const resetError: string | null = await auth.resetPassword(email)

    // if firebase error return to 'email' screen and show error message
    if (resetError) {
      setError(resetError)
      setIsLoading(false)
      setIsButtonDisabled(false)
      Analytics.logEvent(AnalyticsEvent.ResetPasswordRequestFailure, { email, error })
      return resetError
    }
    // last reset time from local storage
    const lastResetTime: string | undefined = localStorageService.getValue(
      LocalStorageKeys.LAST_RESET_TIME,
    )

    // set last reset time if do not have it
    if (!lastResetTime) {
      localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, String(Date.now()))
    }

    Analytics.logEvent(AnalyticsEvent.ResetPasswordRequestSuccess, { email })
    return null
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget
    setEmail(value)

    // reset error when user have error and then start typing again
    setError('')
  }

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // when user on 'email' screen and first click on button to send request
    if (screen === 'email') {
      const emailError: string | null = validateEmail(email)

      // if error in email - set error, else - show 'reset' screen
      if (emailError) {
        setError(emailError)
      } else {
        setScreen('reset')
      }
    }
    // when user on 'reset' screen and user wants to resend email after 60s timeout
    else {
      setSeconds(60)
      const resetError = await sendResetPasswordEmail()

      // if no firebase error - start intervals
      if (!resetError) {
        startIntervals(60)
      }
    }
  }

  const handleChangeEmail = () => {
    const userEmail = localStorageService.getValue(
      LocalStorageKeys.RESET_PASSWORD_USER_EMAIL,
    )
    // set user email to state from local storage
    if (userEmail) setEmail(userEmail)

    // clear local storage
    localStorageService.setValue(LocalStorageKeys.FIRST_RESET_REQUEST, '')
    localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, '')
    localStorageService.setValue(LocalStorageKeys.RESET_PASSWORD_USER_EMAIL, '')

    // change screen
    setScreen('email')
  }

  return (
    <div css={[styles.flexContainer, styles.resetPassword]}>
      <Background />
      <div css={[styles.flexContainer, styles.container]}>
        <form css={styles.form.container} onSubmit={handleSubmitForm}>
          <Title extendCss={styles.form.title}>
            {screen === 'reset' ? 'Email has been sent' : 'Reset password'}
          </Title>
          <p css={styles.form.prompt}>
            {screen === 'reset'
              ? 'Please check your inbox and click on the received link to reset a password'
              : 'Enter your registered email below to receive password reset instruction'}
          </p>
          {screen === 'email' && (
            <InputField
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              required
              extendCss={styles.form.input}
              value={email}
              onChange={handleInputChange}
            />
          )}

          <div css={styles.error}>{error}</div>

          <Button
            isDisabled={(!email && screen === 'email') || !!error || isButtonDisabled}
            extendCss={styles.form.button}
            isLoading={isLoading}
          >
            {screen === 'reset'
              ? isButtonDisabled
                ? `Resend email in ${seconds}s`
                : 'Resend email'
              : 'Send email'}
          </Button>
          {screen === 'reset' && (
            <p css={styles.form.changeEmail} onClick={handleChangeEmail}>
              Change email
            </p>
          )}
        </form>
      </div>
    </div>
  )
}
