import { css } from '@emotion/react'

export const styles = {
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  register: css`
    height: 100vh;
    justify-content: space-between;
    padding: 0 24px;
  `,

  container: css`
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 97px;
  `,
}
