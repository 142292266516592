import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 100vh;
  `,

  title: css`
    margin-bottom: 14px;
  `,

  text: css`
    max-width: 266px;
    font-size: 14px;
    line-height: 18px;
    color: ${Colors.Dark2};
    text-align: center;

    max-width: 330px;
  `,

  textShort: css`
    max-width: 176px;
  `,

  button: css`
    margin: 46px 0;
  `,

  socials: {
    container: css`
      margin-top: 18px;

      display: flex;
      flex-direction: row;
      align-items: center;
    `,

    item: {
      container: css`
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0 10px;
        cursor: pointer;
      `,

      icon: css`
        margin-right: 6px;
        width: 15px;
        height: 15px;
      `,

      link: css`
        text-decoration: underline;
        color: ${Colors.Dark1};
      `,
    },
  },

  signOut: css`
    position: absolute;
    bottom: 4vh;

    text-decoration: underline;
    color: ${Colors.Dark2};
    cursor: pointer;
  `,
}
