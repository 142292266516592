import { css } from '@emotion/react'

export const styles = {
  header: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 205px 10px 90px;
    position: fixed;
    z-index: 1000;

    @media (max-width: 992px) {
      padding: 39px 130px 10px 50px;
    }

    @media (max-width: 768px) {
      padding: 39px 24px 10px 24px;
    }
  `,

  logo: css`
    display: block;
    @media (max-width: 480px) {
      width: 81px;
    }
  `,
}
