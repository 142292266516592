import { Link, LinkProps } from '@reach/router'
import { FC } from 'react'
import { Colors } from '~/colors'
import { styles } from './styles'

interface NavLinkProps extends LinkProps<unknown> {}

/* NavLink used in Header (modified Link from router library)
 * Used to show active page on Header
 */
export const NavLink: FC<NavLinkProps> = ({ to, children }) => (
  <Link
    to={to}
    css={styles.navLink}
    getProps={({ isCurrent }) => ({
      style: {
        borderBottom: isCurrent ? `2px solid ${Colors.Dark2}` : 'none',
      },
    })}
  >
    {children}
  </Link>
)
