import { FC, useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import useSWR from 'swr'

import { AuthContext, RequestApiContext } from '~/context'
import { Title, Button } from '~/components'
import { User } from '~/types'
import { styles } from './styles'

export const HomeScreen: FC<RouteComponentProps> = () => {
  const { decodedToken, signOut } = useContext(AuthContext)
  const { requestApi } = useContext(RequestApiContext)

  // get user
  const { data: { user } = {} } = useSWR<{ user: User }>(
    `/users?user_id=${decodedToken?.user_id}`,
    (url) => requestApi({ url }),
  )

  // if user is not loaded yet, show loader
  if (!user) {
    return null
  }

  return (
    <div css={styles.container}>
      <Title css={styles.title}>Hi, {user?.name}</Title>

      {/* <p css={styles.text}>
        Welcome to Ryddm! Our mobile app is still in development, but you can download the
        beta version by clicking the button below.
      </p> */}

      <p css={styles.text}>
        Welcome to Ryddm! Our mobile app is still in development. You will soon be able to
        download the beta by clicking the button below.
      </p>

      <Button css={styles.button} isDisabled>
        Beta coming soon
      </Button>

      <p css={[styles.text, styles.textShort]}>
        Be sure to follow us on social media to stay tuned!
      </p>

      <div css={styles.socials.container}>
        <a
          css={styles.socials.item.container}
          href="https://instagram.com/Ryddm"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            css={styles.socials.item.icon}
            src={require('./assets/instagram.svg')}
            alt="instagram"
          />
          <div css={styles.socials.item.link}>@Ryddm</div>
        </a>

        <a
          css={styles.socials.item.container}
          href="https://facebook.com/RyddmApp"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            css={styles.socials.item.icon}
            src={require('./assets/facebook.svg')}
            alt="facebook"
          />
          <div css={styles.socials.item.link}>@RyddmApp</div>
        </a>
      </div>

      <p css={[styles.text, styles.signOut]} onClick={signOut}>
        Sign out
      </p>
    </div>
  )
}
