import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  navLink: css`
    margin-left: 30px;
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.Dark1};
    text-decoration: none;

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 18px;
    }
  `,
}
