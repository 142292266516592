import mixpanel from 'mixpanel-browser'
import { firebaseApp } from '~/firebase'
import { User } from '~/types'

// AnalyticsEvent provides all possible analytics events.
export enum AnalyticsEvent {
  RegisterSuccess = 'register_success',
  RegisterFailure = 'register_failure',
  LoginSuccess = 'login_success',
  LoginFailure = 'login_failure',
  ResendEmailSuccess = 'resend_email_success',
  ResendEmailFailure = 'resend_email_failure',
  VerifyEmailSuccess = 'verify_email_success',
  ResetPasswordRequestSuccess = 'reset_password_request_success',
  ResetPasswordRequestFailure = 'reset_password_request_failure',
}

// Analytics provides functionality for collecting user analytics.
export class Analytics {
  private static google = firebaseApp.analytics()

  public static logEvent(event: AnalyticsEvent, data?: Record<string, any>) {
    try {
      mixpanel.track(event, data)
      Analytics.google.logEvent(event, data)
    } catch (error) {
      console.error('failed to track event', error)
    }
  }

  public static setUser(user: Partial<User>) {
    try {
      // set user id
      this.google.setUserId(user.id!)
      mixpanel.identify(user.id)

      // set mixpanel user props
      const { email, avatar_url, name, ...rest } = user
      mixpanel.people.set({
        ...rest,
        $name: name,
        $email: email,
        $avatar: avatar_url,
      })
    } catch (error) {
      console.error('failed to save user data', error)
    }
  }
}
