import { css, keyframes } from '@emotion/react'
import { Colors } from '~/colors'

const animations = {
  spin: keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`,
}

export const styles = {
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 44px;
    min-height: 44px;
    max-width: 350px;
    width: 100%;
    padding: 12px 0;
    border-radius: 68px;
    border: none;
    background-color: ${Colors.Dark1};
    color: ${Colors.Light2};
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &:active {
      background-color: ${Colors.Dark2};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: ${Colors.Dark2};
    }

    @media (max-width: 480px) {
      /* max-width: 230px; */
    }
  `,
  loader: css`
    animation: ${animations.spin} 1.2s linear infinite;
  `,
}
