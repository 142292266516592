import { createContext } from 'react'
import { RequestApiParams } from '~/services'

type RequestApiContextProps = {
  requestApi: <T = void>(params: RequestApiParams) => Promise<T>
}

export const RequestApiContext = createContext<RequestApiContextProps>({
  requestApi: () => undefined!,
})
