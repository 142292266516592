import { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { firebaseApp } from '~/firebase'
import { localStorageService, LocalStorageKeys, Analytics } from '~/services'

type AuthContextProps = {
  isReady: boolean
  token: string
  decodedToken?: Record<string, string>
  emailVerified: boolean
  setAuthState: Dispatch<SetStateAction<{ token?: string; emailVerified?: boolean }>>
  signOut: () => void
}

export const AuthContext = createContext<AuthContextProps>({
  isReady: false,
  token: undefined!,
  decodedToken: undefined,
  emailVerified: undefined!,
  setAuthState: () => undefined,
  signOut: () => undefined,
})

export const AuthProvider: FC = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const [authState, setAuthState] = useState<Partial<AuthContextProps>>({
    token: undefined,
    emailVerified: undefined,
    decodedToken: undefined,
  })

  // get auth data on mount
  useEffect(() => {
    // get token from local storage
    const token = localStorageService.getValue(LocalStorageKeys.TOKEN) || ''

    // save token and decoded token
    setAuthState((prev) => ({ ...prev, token }))

    // get emailVerified from local storage
    const lsEmailVerified = localStorageService.getValue(LocalStorageKeys.EMAIL_VERIFIED)
    if (lsEmailVerified) {
      setAuthState((prev) => ({ ...prev, emailVerified: JSON.parse(lsEmailVerified) }))
    } else {
      // if there's no emailVerified in local storage, subscribe to Firebase user changes
      const unsubscribe = firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
          setAuthState((prev) => ({
            ...prev,
            emailVerified: user.emailVerified,
          }))
          unsubscribe()

          localStorageService.setValue(
            LocalStorageKeys.EMAIL_VERIFIED,
            JSON.stringify(user.emailVerified),
          )
        }
      })
    }
  }, [])

  // when token and emailVerified are no longer undefined, app is ready
  useEffect(() => {
    if (authState.token === undefined || authState.emailVerified === undefined) {
      setIsReady(true)
    }
  }, [authState.token, authState.emailVerified])

  // update token in local storage when it's updated in Context
  useEffect(() => {
    localStorageService.setValue(LocalStorageKeys.TOKEN, authState.token || '')
  }, [authState.token])

  // update emailVerified in local storage when it's updated in Context
  useEffect(() => {
    if (authState.emailVerified !== undefined) {
      localStorageService.setValue(
        LocalStorageKeys.EMAIL_VERIFIED,
        JSON.stringify(authState.emailVerified),
      )
    }
  }, [authState.emailVerified])

  // decode token when it's changed
  useEffect(() => {
    if (authState.token) {
      // decode token
      const decodedToken = jwtDecode(authState.token) as
        | Record<string, string>
        | undefined

      setAuthState((prev) => ({ ...prev, decodedToken }))

      Analytics.setUser({ id: decodedToken?.id, role: 'fan' })
    }
  }, [authState.token])

  // manage signing out
  const signOut = () => {
    setAuthState({ token: '', emailVerified: undefined })
    localStorageService.purge()

    return firebaseApp.auth().signOut()
  }

  return (
    <AuthContext.Provider
      value={{
        isReady,
        token: authState.token!,
        decodedToken: authState.decodedToken,
        emailVerified: authState.emailVerified!,
        setAuthState,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
