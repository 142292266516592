import { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, Link, navigate } from '@reach/router'

import { Title, Button } from '~/components'
import { Background, InputField } from '~/screens/auth/components'
import { auth } from '~/screens/auth/firebase/firebase-auth'
import { validateEmail, validatePassword } from '~/screens/auth/utils/validators'
import {
  Analytics,
  AnalyticsEvent,
  LocalStorageKeys,
  localStorageService,
} from '~/services'
import { RequestApiContext, AuthContext } from '~/context'
import { firebaseApp } from '~/firebase'
import { ServerUser } from '~/types'

import { styles } from './styles'

interface User {
  email: string
  password: string
}

export const LoginScreen: FC<RouteComponentProps> = () => {
  const [user, setUser] = useState<User>({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const requestApiContext = useContext(RequestApiContext)
  const authContext = useContext(AuthContext)

  // clear info about reset password in local storage
  useEffect(() => {
    localStorageService.setValue(LocalStorageKeys.FIRST_RESET_REQUEST, '')
    localStorageService.setValue(LocalStorageKeys.LAST_RESET_TIME, '')
    localStorageService.setValue(LocalStorageKeys.RESET_PASSWORD_USER_EMAIL, '')
  }, [])

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget
    setUser((prev) => ({ ...prev, [name]: value }))

    // reset errors when user have error and then start typing again
    setErrors([])
  }

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const emailError: string | null = validateEmail(user.email)
    const passwordError: string | null = validatePassword(user.password)

    if (emailError) setErrors((prev) => [...prev, emailError])
    if (passwordError) setErrors((prev) => [...prev, passwordError])

    // if email and password valid - start login
    if (!emailError && !passwordError) {
      setIsLoading(true)

      // login firebase user
      const authErr = await auth.login(user.email, user.password)

      if (authErr) {
        setErrors((prev) => [...prev, authErr])
        setIsLoading(false)
      } else {
        // get ID Token firebase user
        const idToken = await firebaseApp.auth().currentUser?.getIdToken()

        try {
          // get auth token from API request
          const apiResponse = await requestApiContext.requestApi<{
            authToken: string
            user: ServerUser
          }>({
            url: '/auth/login',
            options: {
              method: 'POST',
              body: JSON.stringify({
                idToken,
              }),
            },
          })
          setIsLoading(false)

          // set token to the context
          authContext.setAuthState((prev) => ({ ...prev, token: apiResponse.authToken }))

          // redirect to home screen
          Analytics.logEvent(AnalyticsEvent.LoginSuccess, { user })
          navigate('/')
        } catch (error) {
          // expected error
          if (error === 'failed to parse request body') {
            console.debug('expected api error', error)
            setErrors((prev) => [...prev, 'Email or password are invalid.'])
          } else {
            // unexpected errors
            console.error('unexpected api error', error)
            setErrors((prev) => [...prev, error.message])
          }

          Analytics.logEvent(AnalyticsEvent.LoginFailure, { user, error })
          setIsLoading(false)
        }
      }
    }
  }

  return (
    <div css={[styles.flexContainer, styles.login]}>
      <Background />

      <div css={[styles.flexContainer, styles.container]}>
        <form css={styles.form.container} onSubmit={handleSubmitForm}>
          <Title extendCss={styles.form.title}>Welcome</Title>
          <InputField
            type="email"
            id="email"
            name="email"
            placeholder="Your email"
            required
            extendCss={styles.form.input}
            value={user.email}
            onChange={handleInputChange}
            data-testid="login_input_email"
          />
          <InputField
            type="password"
            id="password"
            name="password"
            placeholder="Your password"
            required
            extendCss={styles.form.input}
            value={user.password}
            onChange={handleInputChange}
            data-testid="login_input_password"
          />

          {errors.map((error) => (
            <div key={error} css={styles.error} data-testid="login_errors">
              {error}
            </div>
          ))}

          <Button
            isDisabled={!user.password || !user.email || !!errors.length}
            extendCss={styles.form.button}
            isLoading={isLoading}
            data-testid="login_button"
          >
            Log in
          </Button>
          <Link
            to="/auth/reset"
            css={styles.form.question}
            data-testid="login_forgot_password_button"
          >
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  )
}
