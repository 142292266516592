import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing/dist/browser/browsertracing'
import { CaptureConsole } from '@sentry/integrations/dist/captureconsole'
import mixpanel from 'mixpanel-browser'

import { App } from './app'
import { UI_VERSION } from './config'
import { AuthProvider } from './context'

// initialize sentry log collection and monitoring
if (UI_VERSION !== 'local') {
  mixpanel.init('3cf85bae3344c10f5ea0ae9284bbd24f')
  Sentry.init({
    dsn: 'https://d5fc9ee3f4a04548a8646c5d90bb1ae6@o473911.ingest.sentry.io/5643224',
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root'),
)
