import { FC } from 'react'

import { styles } from './styles'

/* Background need for register, login, confirm email and reset password screens
 * It is used to show gray circle on background
 * Usage: put this component inside your main component(it will not affect other components)
 */
export const Background: FC = () => {
  return <div css={styles.background} />
}
