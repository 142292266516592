import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  section: css`
    max-width: 350px;
    width: 100%;
    text-align: center;

    @media (max-width: 480px) {
      /* max-width: 230px; */
    }
  `,

  title: css`
    margin-bottom: 88px;

    @media (max-width: 480px) {
      margin-bottom: 81px;
    }
  `,

  button: css`
    margin-top: 46px;

    @media (max-width: 480px) {
      margin-top: 37px;
    }
  `,

  error: css`
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
    color: ${Colors.Red};

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,
}
