import { SerializedStyles } from '@emotion/react'
import { FC, InputHTMLAttributes } from 'react'

import { styles } from './styles'

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  extendCss?: SerializedStyles
}

/* InputField need to provide universal field that
 * can be used in forms on register, login and other screens
 */
export const InputField: FC<InputFieldProps> = ({
  extendCss,
  onChange,
  type,
  ...props
}) => {
  return (
    <input
      {...props}
      type={type}
      css={[
        styles.input.base,
        extendCss,
        type === 'password' ? styles.input.password : null,
      ]}
      onChange={(e) => {
        if (onChange) onChange(e)
      }}
    />
  )
}
