import { css, keyframes } from '@emotion/react'
import { Colors } from '~/colors'

export const animations = {
  // growing animations of progress bar
  expansion: {
    // first stage
    start: keyframes`
      0%{
        width:0%;
      }

      100%{
        width:13.5%;
      }
  `,

    // second stage
    middle: keyframes`
      0%{
        width:13.5%;
      }

      100%{
        width:60%;
      }
    `,

    // third stage
    final: keyframes`
      0%{
        width:60%;
      }
        
      100%{
        width:100%;
      }
      `,
  },
}

export const styles = {
  progress: css`
    width: 100%;
    max-width: 1028px;
    margin-bottom: 125px;

    @media (max-width: 480px) {
      margin-bottom: 38px;
    }
  `,

  points: css`
    display: flex;
    justify-content: space-between;
  `,

  container: css`
    display: flex;
    justify-content: center;
  `,

  bar: {
    // on other stage need other animation
    start: css`
      width: 100%;
      max-width: 1000px;
      height: 2px;
      background-color: ${Colors.Light4};
      position: relative;
      margin: 0 14px;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        background-color: ${Colors.Dark1};
        animation: ${animations.expansion.start} 1s ease forwards;
      }
    `,

    middle: css`
      &:after {
        animation: ${animations.expansion.middle} 1s ease forwards;
      }
    `,

    final: css`
      &:after {
        animation: ${animations.expansion.final} 1s ease forwards;
      }
    `,
  },
}
