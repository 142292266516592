import { FC, Fragment, Suspense, useContext, useMemo } from 'react'
import { Redirect, Router } from '@reach/router'

import { AuthContext, RequestApiContext } from './context'
import {
  RegisterScreen,
  LoginScreen,
  ConfirmEmailScreen,
  ResetPasswordScreen,
} from './screens/auth/screens'
import { HomeScreen } from './screens'
import { RequestApiService } from './services'
import { Header } from './components'

export const App: FC = () => {
  const authContext = useContext(AuthContext)

  const requestApiService = useMemo(() => new RequestApiService(authContext.signOut), [])

  if (!authContext.isReady) {
    return null
  }

  if (authContext.token !== '' && authContext.emailVerified === false) {
    return (
      <RequestApiContext.Provider value={{ requestApi: requestApiService.requestApi }}>
        <Suspense fallback={<div>loading...</div>}>
          <Header />
          <Router>
            <ConfirmEmailScreen path="/auth/confirm" />

            <Redirect from="*" to="/auth/confirm" noThrow />
          </Router>
        </Suspense>
      </RequestApiContext.Provider>
    )
  }

  if (authContext.token === '') {
    return (
      <RequestApiContext.Provider value={{ requestApi: requestApiService.requestApi }}>
        <Fragment>
          <Header />
          <Suspense fallback={<div>loading...</div>}>
            <Router>
              <RegisterScreen path="/auth/register" />
              <LoginScreen path="/auth/login" />
              <ResetPasswordScreen path="/auth/reset" />

              <Redirect
                from="*"
                to={authContext.token === '' ? '/auth/register' : '/auth/confirm'}
                noThrow
              />
            </Router>
          </Suspense>
        </Fragment>
      </RequestApiContext.Provider>
    )
  }

  return (
    <RequestApiContext.Provider value={{ requestApi: requestApiService.requestApi }}>
      <Suspense fallback={<div>loading...</div>}>
        <Router>
          <HomeScreen path="/" />

          <Redirect from="*" to="/" noThrow />
        </Router>
      </Suspense>
    </RequestApiContext.Provider>
  )
}
