import { FC } from 'react'
import logo from './assets/images/black-logo.svg'
import { styles } from './styles'
import { NavLink } from '..'

// Header used on all screens for showing navigation links
export const Header: FC = () => {
  return (
    <header css={styles.header}>
      <img src={logo} css={styles.logo} alt="Black logo." />
      <nav>
        <NavLink to="/auth/login">Log in</NavLink>
        <NavLink to="/auth/register">Create account</NavLink>
      </nav>
    </header>
  )
}
