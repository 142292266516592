import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  login: css`
    height: 100vh;
    padding: 0 24px;
  `,

  container: css`
    justify-content: center;
    height: 100%;
    width: 100%;
  `,

  form: {
    container: css`
      width: 100%;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
    `,

    title: css`
      margin-bottom: 44px;

      @media (max-width: 480px) {
        margin-bottom: 51px;
      }
    `,

    input: css`
      margin-top: 44px;

      @media (max-width: 480px) {
        margin-top: 30px;
      }
    `,

    button: css`
      margin-top: 46px;

      @media (max-width: 480px) {
        margin-top: 37px;
      }
    `,

    question: css`
      margin-top: 20px;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: ${Colors.Light5};

      &:hover {
        color: ${Colors.Dark1};
      }

      @media (max-width: 480px) {
        margin-top: 12px;
      }
    `,
  },

  error: css`
    font-size: 14px;
    margin-top: 10px;
    align-self: flex-start;
    color: ${Colors.Red};

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,
}
