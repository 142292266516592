import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  input: {
    base: css`
      display: block;
      width: 100%;
      max-width: 350px;
      border: none;
      border-bottom: 1px solid ${Colors.Light4};
      padding: 0 14px 6px 14px;
      font-size: 20px;
      line-height: 25px;
      color: ${Colors.Dark1};
      background-color: ${Colors.Light6};

      &::placeholder {
        color: ${Colors.Light5};
        letter-spacing: normal;
        font-size: 20px;
      }

      &:focus {
        outline: none;
      }

      /* because on this width Background hides
       * and input needs other background color
       */
      @media (max-width: 992px) {
        background-color: ${Colors.Light1};
      }

      @media (max-width: 480px) {
        font-size: 18px;
        line-height: 23px;
        /* max-width: 230px; */
      }
    `,

    password: css`
      font-size: 16px;
      letter-spacing: 0.23em;
    `,
  },
}
