import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  checkbox: {
    container: css`
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 14px;
      }

      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;
    `,

    check: css`
      width: 16px;
      height: 16px;
      flex-shrink: 0;

      border: 1.5px solid ${Colors.Dark2};
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;
    `,

    checked: css`
      border: 1.5px solid ${Colors.Dark1};
    `,

    icon: css`
      width: 14px;
      height: 14px;
    `,

    text: css`
      font-size: 14px;
      margin-top: 1.5px;

      color: ${Colors.Dark2};
      font-weight: normal;
      margin-left: 12px;

      a {
        color: inherit;
      }
    `,

    textChecked: css`
      color: ${Colors.Dark1};
    `,
  },
}
