export enum Colors {
  Dark1 = '#1D1D1D',
  Dark2 = '#787878',
  Light1 = '#FFFFFF',
  Light2 = '#F7F7F7',
  Light3 = '#F4F4F4',
  Light4 = '#E7E7E7',
  Light5 = '#B9B9B9',
  Light6 = '#FAFAFA',
  Red = '#F23F2C',
}
