import { FC, FormEvent } from 'react'
import { Title, Button, Checkbox } from '~/components'
import { InputField } from '~/screens/auth/components'
import { styles } from './styles'

interface FormProps {
  email: string
  password: string
  errors: string[]
  isLoading: boolean
  onInputChange: (e: FormEvent<HTMLInputElement>) => void
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void

  termsAccepted: boolean
  setTermsAccepted: (termsAccepted: boolean) => void
  emailsAccepted: boolean
  setEmailsAccepted: (emailsAccepted: boolean) => void
}

export const FormSection: FC<FormProps> = ({
  email,
  password,
  onInputChange,

  termsAccepted,
  setTermsAccepted,
  emailsAccepted,
  setEmailsAccepted,

  errors,
  onFormSubmit,
  isLoading,
}) => {
  return (
    <form css={styles.form} onSubmit={onFormSubmit}>
      <Title extendCss={styles.title}>One more thing</Title>
      <InputField
        type="email"
        id="email"
        name="email"
        placeholder="Your email"
        required
        extendCss={styles.input}
        value={email}
        onChange={onInputChange}
        data-testid="access_input_email"
      />
      <InputField
        type="password"
        id="password"
        name="password"
        placeholder="Your password"
        required
        extendCss={styles.input}
        value={password}
        onChange={onInputChange}
        data-testid="access_input_password"
      />

      <div css={styles.checkboxes}>
        <Checkbox
          checked={termsAccepted}
          setChecked={setTermsAccepted}
          data-testid="checkbox_terms"
        >
          Agree to our{' '}
          <a
            href="https://artist.staging.ryddm.com/terms"
            target="_blank"
            rel="noreferrer noopener"
          >
            terms of use
          </a>{' '}
          and{' '}
          <a
            href="https://artist.staging.ryddm.com/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
          >
            privacy policy
          </a>
        </Checkbox>

        <Checkbox
          checked={emailsAccepted}
          setChecked={setEmailsAccepted}
          data-testid="checkbox_emails"
        >
          Agree to receive occasional informational emails
        </Checkbox>
      </div>

      {errors.map((error) => (
        <div key={error} css={styles.error} data-testid="access_errors">
          {error}
        </div>
      ))}

      <Button
        isDisabled={!password || !email || !!errors.length || !termsAccepted}
        extendCss={styles.button}
        isLoading={isLoading}
        data-testid="access_button"
      >
        Finish
      </Button>
    </form>
  )
}
