import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'

import { styles } from './styles'

interface TitleProps {
  extendCss?: SerializedStyles
}

// Title need to show styled title on login, register, confirm email, reset password screens
export const Title: FC<TitleProps> = ({ children, extendCss, ...props }) => {
  return (
    <h1 {...props} css={[styles.title, extendCss]}>
      {children}
    </h1>
  )
}
