import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  point: {
    base: css`
      color: ${Colors.Light4};
      letter-spacing: -0.055em;
      transition: 1s all ease;
    `,

    active: css`
      color: ${Colors.Dark1};
    `,
  },

  number: css`
    font-size: 40px;
    line-height: 50px;

    @media (max-width: 480px) {
      font-size: 30px;
      line-height: 38px;
    }
  `,

  title: css`
    font-size: 22px;
    line-height: 28px;
    padding-left: 18px;

    @media (max-width: 768px) {
      display: none;
    }
  `,
}
