import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDuYj58qK8qf4Dz_5nDRjLTE5v_HZyKv5k',
  authDomain: 'ryddm-286615.firebaseapp.com',
  databaseURL: 'https://ryddm-286615.firebaseio.com',
  projectId: 'ryddm-286615',
  storageBucket: 'ryddm-286615.appspot.com',
  messagingSenderId: '864316200417',
  appId: '1:864316200417:web:3dcbb1ac7cd1685f4aeea0',
  measurementId: 'G-V8LCHLWPMH',
})
