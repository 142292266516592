import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  title: css`
    font-size: 36px;
    line-height: 45px;
    font-weight: 500;
    color: ${Colors.Dark1};

    @media (max-width: 480px) {
      text-align: center;
      font-size: 30px;
      line-height: 38px;
    }
  `,
}
