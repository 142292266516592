import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  confirmEmail: css`
    height: 100vh;
    padding: 0 24px;
  `,

  container: css`
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 330px;
    text-align: center;

    @media (max-width: 480px) {
      max-width: 318px;
    }
  `,

  title: css`
    margin-bottom: 14px;
  `,

  button: css`
    margin-top: 46px;

    @media (max-width: 480px) {
      margin-top: 25px;
    }
  `,

  prompt: {
    base: css`
      font-size: 14px;
      line-height: 18px;
      color: ${Colors.Light5};
      margin-bottom: 12px;
    `,

    accent: css`
      display: block;
      color: ${Colors.Dark1};
    `,
  },

  changeEmail: css`
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: ${Colors.Light5};
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      color: ${Colors.Dark1};
    }

    @media (max-width: 480px) {
      margin-top: 12px;
    }
  `,

  error: css`
    font-size: 14px;
    margin-top: 10px;
    align-self: flex-start;
    color: ${Colors.Red};

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,
}
