import { css } from '@emotion/react'
import { Colors } from '~/colors'

export const styles = {
  container: css`
    width: 100%;
    max-width: 500px;

    @media (max-width: 480px) {
      max-width: 323px;
    }
  `,

  picker: {
    base: css`
      height: 225px;
      width: 100%;
      overflow-y: scroll;
      text-align: center;

      // Hide scrollbar, but still scrolling
      &::-webkit-scrollbar {
        // Safari and Chrome
        display: none;
      }
      -ms-overflow-style: none; // Internet Explorer 10+
      scrollbar-width: none; // Firefox
      overflow: -moz-scrollbars-none; // Older versions of Firefox

      position: relative;
      z-index: 1;
    `,

    left: css`
      text-align: left;
    `,
  },

  // effect of shadow on up and dow of genre picker
  shadow: {
    base: css`
      position: relative;
      width: 100%;
      z-index: 2;
      height: 20px;
    `,

    down: css`
      box-shadow: 0px -6px 15px 5px ${Colors.Light6};

      @media (max-width: 992px) {
        box-shadow: 0px -6px 15px 5px ${Colors.Light1};
      }
    `,

    up: css`
      box-shadow: 0px 6px 15px 5px ${Colors.Light6};

      @media (max-width: 992px) {
        box-shadow: 0px 6px 15px 5px ${Colors.Light1};
      }
    `,
  },

  stopper: css`
    width: 100%;
    height: 25px;
  `,
}
