type LocalStorageData = { [key in LocalStorageKeys]: string | undefined }

export enum LocalStorageKeys {
  TOKEN = '@ryddm/token',
  EMAIL_VERIFIED = '@ryddm/emailVerified',
  LAST_CONFIRM_TIME = '@ryddm/lastConfirmTime',
  LAST_RESET_TIME = '@ryddm/lastResetTime',
  FIRST_RESET_REQUEST = '@ryddm/firstResetRequest',
  FIRST_CONFIRM_REQUEST = '@ryddm/firstConfirmRequest',
  RESET_PASSWORD_USER_EMAIL = '@ryddm/resetPasswordUserEmail',
  REFERRAL_CODE = '@ryddm/referralCode',
}

class LocalStorageService {
  private data: LocalStorageData

  constructor() {
    this.data = this.getLocalStorageData()
  }

  public getValue = (key: LocalStorageKeys) => {
    return this.data[key]
  }

  public setValue = (key: LocalStorageKeys, value: string) => {
    localStorage.setItem(key, value)
    this.data[key] = value
  }

  public purge = () => {
    localStorage.clear()
    Object.values(LocalStorageKeys).forEach((key) => {
      this.data[key] = undefined
    })
  }

  private getLocalStorageData = () => {
    return Object.values(LocalStorageKeys).reduce(
      (acc, cur) => ({ ...acc, [cur]: localStorage.getItem(cur) || undefined }),
      {} as LocalStorageData,
    )
  }
}

export const localStorageService = new LocalStorageService()
