import { firebaseApp } from '~/firebase'

const firebaseErrorLabels = new Map([
  ['auth/email-already-in-use', 'Email address is already in use.'],
  ['auth/invalid-email', 'Email address is invalid.'],
  ['auth/wrong-password', 'Invalid email or password.'],
  ['auth/too-many-requests', 'Too many requests, try later.'],
  ['auth/user-not-found', 'Invalid email or password.'],
  ['auth/weak-password', 'Password must be at least 6 characters.'],
  ['auth/user-disabled', "This user's account has been disabled."],
  ['auth/wrong-password', 'Invalid password.'],
])

export const auth = {
  async signUp(email: string, password: string) {
    try {
      await firebaseApp.auth().createUserWithEmailAndPassword(email, password)
    } catch (error) {
      // expected errors
      if (firebaseErrorLabels.has(error.code)) {
        console.debug('Expected firebase error', error.code)

        return firebaseErrorLabels.get(error.code)!
      }

      // unexpected errors
      console.error('Unexpected firebase error', error)

      // delete Firebase user if API request for registration failed
      firebaseApp.auth().currentUser?.delete()

      return error.message
    }

    return null
  },

  async login(email: string, password: string) {
    try {
      await firebaseApp.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      // expected errors
      if (firebaseErrorLabels.has(error.code)) {
        console.debug('Expected firebase error', error.code)

        return firebaseErrorLabels.get(error.code)!
      }

      // unexpected errors
      console.error('Unexpected firebase error', error)

      return error.message
    }

    return null
  },

  async confirmEmail() {
    try {
      await firebaseApp.auth().currentUser?.sendEmailVerification()
    } catch (error) {
      // expected errors
      if (firebaseErrorLabels.has(error.code)) {
        console.debug('Expected firebase error', error.code)

        return firebaseErrorLabels.get(error.code)!
      }

      // unexpected errors
      console.error('Unexpected firebase error', error)

      return error.message
    }

    return null
  },

  async resetPassword(email: string) {
    try {
      await firebaseApp.auth().sendPasswordResetEmail(email)
    } catch (error) {
      // expected errors
      if (firebaseErrorLabels.has(error.code)) {
        console.debug('Expected firebase error', error.code)

        return firebaseErrorLabels.get(error.code)!
      }

      // unexpected errors
      console.error('Unexpected firebase error', error)

      return error.message
    }

    return null
  },
}
