import { SerializedStyles } from '@emotion/react'
import { FC } from 'react'

import { styles } from './styles'
import loader from './assets/images/loader.svg'

interface ButtonProps {
  extendCss?: SerializedStyles
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

/* Button universal button that can be used in forms(or not)
 * Need on login, register, confirm email, reset password screens
 */
export const Button: FC<ButtonProps> = ({
  isDisabled,
  children,
  extendCss,
  onClick,
  isLoading,
  ...props
}) => {
  return (
    <button
      {...props}
      type="submit"
      disabled={isDisabled}
      css={[styles.button, extendCss]}
      onClick={onClick}
    >
      {isLoading ? <img src={loader} alt="Loader." css={styles.loader} /> : children}
    </button>
  )
}
