import { FC, useContext, useEffect, useState } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { Title, Button } from '~/components'
import { Background } from '~/screens/auth/components'
import { auth } from '~/screens/auth/firebase/firebase-auth'
import { firebaseApp } from '~/firebase'
import { AuthContext } from '~/context'
import {
  Analytics,
  AnalyticsEvent,
  LocalStorageKeys,
  localStorageService,
} from '~/services'

import { styles } from './styles'

export const ConfirmEmailScreen: FC<RouteComponentProps> = () => {
  const [error, setError] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [seconds, setSeconds] = useState<number>(60)
  const [email, setEmail] = useState<string>('')

  const authContext = useContext(AuthContext)

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user?.email) {
        setEmail(user.email)
      }
    })
  }, [])

  // calc all intervals when confirm screen is mounted
  useEffect(() => {
    // func for making first request
    const makeFirstRequest = async () => {
      setSeconds(60)
      const resetError = await sendConfirmEmail()

      // if no firebase error - start intervals
      if (!resetError) {
        startIntervals(60)
      }
    }

    // first reset request
    const isFirstConfirmRequest = Boolean(
      localStorageService.getValue(LocalStorageKeys.FIRST_CONFIRM_REQUEST),
    )

    // time of last confirmation in ms
    const lastConfirmTime: string | undefined = localStorageService.getValue(
      LocalStorageKeys.LAST_CONFIRM_TIME,
    )

    // when do not have first time request after showing 'confirm' screen
    if (!isFirstConfirmRequest) {
      localStorageService.setValue(
        LocalStorageKeys.FIRST_CONFIRM_REQUEST,
        String(Date.now()),
      )
      makeFirstRequest()
    }
    // when reopen page - we have last confirm time, but first confirm request already is
    else if (lastConfirmTime) {
      // time now in ms
      const timeNow: number = Date.now()

      /* difference between the current time and the time
       * of the last confirmation
       */
      const timeDifference: number = Math.floor(
        (timeNow - Number(lastConfirmTime)) / 1000,
      )

      /* if the time that has passed since the last
       * confirmation is more than 60 seconds, make the button active,
       * and in the local storage set an empty value
       */
      if (timeDifference > 60) {
        setSeconds(0)
        localStorageService.setValue(LocalStorageKeys.LAST_CONFIRM_TIME, '')
      } else {
        // else set the remaining seconds and intervals
        setSeconds(60 - timeDifference)
        startIntervals(60 - timeDifference)
      }
    }
  }, [])

  useEffect(() => {
    // when seconds changed check if 0 make button active
    if (seconds === 0) {
      setIsButtonDisabled(false)
    }
  }, [seconds])

  const startIntervals = (clearTime: number) => {
    setIsLoading(false)
    setIsButtonDisabled(true)

    // decrease seconds every 1s
    const intervalDecreaseSecondsId = setInterval(
      () => setSeconds((seconds) => seconds - 1),
      1000,
    )

    /* check every 3 seconds the user has confirmed the mail
     * if yes - clear all intervals, update context and redirect to home
     */
    const intervalCheckVerifiedEmail = setInterval(async () => {
      await firebaseApp.auth().currentUser?.reload()

      if (firebaseApp.auth().currentUser?.emailVerified) {
        clearInterval(intervalCheckVerifiedEmail)
        clearInterval(intervalDecreaseSecondsId)

        // clear local storage
        localStorageService.setValue(LocalStorageKeys.LAST_CONFIRM_TIME, '')
        localStorageService.setValue(LocalStorageKeys.FIRST_CONFIRM_REQUEST, '')

        authContext.setAuthState((prev) => ({ ...prev, emailVerified: true }))

        // redirect to home
        Analytics.logEvent(AnalyticsEvent.VerifyEmailSuccess, { email })
        navigate('/')
      }
    }, 3000)

    /* clear intervals after clearTime and
     * set empty value of last confirm time to local storage
     */
    setTimeout(() => {
      clearInterval(intervalCheckVerifiedEmail)
      clearInterval(intervalDecreaseSecondsId)
      localStorageService.setValue(LocalStorageKeys.LAST_CONFIRM_TIME, '')
    }, clearTime * 1000)
  }

  const sendConfirmEmail = async () => {
    setIsLoading(true)

    // confirm email with firebase
    const confirmError: string | null = await auth.confirmEmail()

    if (confirmError) {
      setError(confirmError)
      setIsButtonDisabled(false)
      setIsLoading(false)

      return confirmError
    }
    // get last confirm time from local storage
    const lastConfirmTime: string | undefined = localStorageService.getValue(
      LocalStorageKeys.LAST_CONFIRM_TIME,
    )

    // set last confirm time if it first confirmation or confirm time is empty
    if (!lastConfirmTime) {
      localStorageService.setValue(LocalStorageKeys.LAST_CONFIRM_TIME, String(Date.now()))
    }

    Analytics.logEvent(AnalyticsEvent.ResendEmailSuccess, { email })
    return null
  }

  const handleClick = async () => {
    setSeconds(60)
    const confirmError = await sendConfirmEmail()
    if (!confirmError) startIntervals(60)
  }

  return (
    <div css={[styles.flexContainer, styles.confirmEmail]}>
      <Background />
      <div css={[styles.flexContainer, styles.container]}>
        <Title extendCss={styles.title}>Confirm your email</Title>
        <p css={styles.prompt.base}>
          We’ve sent confirmation email to:{' '}
          <span css={styles.prompt.accent} data-testid="confirm_loader_email">
            {email || 'Loading...'}
          </span>
        </p>
        <p css={styles.prompt.base}>
          Check your email and click on the confirmation link to continue
        </p>

        <div css={styles.error}>{error}</div>

        <Button
          isDisabled={isButtonDisabled}
          isLoading={isLoading}
          extendCss={styles.button}
          onClick={handleClick}
          data-testid="confirm_button"
        >
          {isButtonDisabled ? `Resend email in ${seconds}s` : 'Resend email'}
        </Button>
        <p
          css={styles.changeEmail}
          onClick={authContext.signOut}
          data-testid="confirm_change_email_button"
        >
          Change email
        </p>
      </div>
    </div>
  )
}
