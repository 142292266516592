import { FC, HTMLAttributes } from 'react'
import { styles } from './styles'

interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
  checked: boolean
  setChecked: (checked: boolean) => any
  'data-testid': string
}

// Checkbox is used to display a checkbox and a text.
// Primarily used on the register screen.
export const Checkbox: FC<CheckboxProps> = ({
  checked,
  setChecked,
  children,
  'data-testid': dataTestId,
  ...props
}) => (
  <div css={styles.checkbox.container} onClick={() => setChecked(!checked)} {...props}>
    <div
      css={[styles.checkbox.check, checked && styles.checkbox.checked]}
      data-testid={dataTestId}
    >
      {checked && (
        <img
          css={styles.checkbox.icon}
          src={require('~/assets/check.svg')}
          alt="checked"
        />
      )}
    </div>
    <div css={[styles.checkbox.text, checked && styles.checkbox.textChecked]}>
      {children}
    </div>
  </div>
)
