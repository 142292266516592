import { FC } from 'react'

import { IGenre } from '~/types'

import { styles } from './styles'
import { GenreItem } from '..'

interface GenresPickerProps {
  tags: IGenre[]
  selectedTags: string[]
  searchQuery: string
  onTagSelect: (tag: IGenre) => void
}

export const GenresPicker: FC<GenresPickerProps> = ({
  tags,
  onTagSelect,
  selectedTags,
  searchQuery,
}) => {
  // need to render list of genres
  const renderGenres = (tags: IGenre[]) => {
    return tags.map((tag) => {
      const isActive: boolean = selectedTags.some(
        (selectedGenre) => selectedGenre === tag.value,
      )
      return (
        <GenreItem tag={tag} isActive={isActive} onClick={onTagSelect} key={tag.value} />
      )
    })
  }

  return (
    <div css={styles.container}>
      <div css={[styles.shadow.base, styles.shadow.up]} />
      <div
        css={[
          styles.picker.base,
          searchQuery !== '' && tags.length !== 0 ? styles.picker.left : null,
        ]}
      >
        <div css={styles.stopper} />
        {renderGenres(tags)}
        {!tags.length && <div data-testid="no_such_tag">There is no such genre...</div>}
        <div css={styles.stopper} />
      </div>
      <div css={[styles.shadow.base, styles.shadow.down]} />
    </div>
  )
}
