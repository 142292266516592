import { css } from '@emotion/react'

export const styles = {
  title: css`
    margin-bottom: 47px;

    @media (max-width: 480px) {
      max-width: 271px;
      margin-bottom: 42px;
    }
  `,

  input: css`
    margin-bottom: 7px;
  `,

  button: css`
    margin-top: 19px;

    @media (max-width: 480px) {
      margin-top: 4px;
    }
  `,
}
