import { FC } from 'react'

import { IGenre } from '~/types'

import { styles } from './styles'

interface GenreItemProps {
  tag: IGenre
  isActive: boolean
  onClick: (tag: IGenre) => void
}

export const GenreItem: FC<GenreItemProps> = ({ tag, isActive, onClick, ...props }) => {
  return (
    <div
      {...props}
      css={[styles.tag.base, isActive ? styles.tag.active : null]}
      onClick={() => onClick(tag)}
      data-testid="tag"
    >
      {tag.label}
    </div>
  )
}
