import { FC, FormEvent } from 'react'

import { Title, Button } from '~/components'
import { InputField } from '~/screens/auth/components'
import { styles } from './styles'

interface NameProps {
  name: string
  updateProgress: () => void
  onInputChange: (event: FormEvent<HTMLInputElement>) => void
  errors: string[]
}

export const NameSection: FC<NameProps> = ({
  updateProgress,
  onInputChange,
  name,
  errors,
}) => {
  return (
    <section css={styles.section}>
      <Title extendCss={styles.title}>Create account</Title>
      <InputField
        type="text"
        id="name"
        name="name"
        placeholder="Your name"
        onChange={onInputChange}
        value={name}
        required
        data-testid="input_name"
      />

      {errors.map((error) => (
        <div key={error} css={styles.error} data-testid="name_error">
          {error}
        </div>
      ))}

      <Button
        isDisabled={!name || !!errors.length}
        extendCss={styles.button}
        onClick={updateProgress}
        data-testid="name_button_next"
      >
        Next
      </Button>
    </section>
  )
}
