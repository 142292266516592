const minLength = (len: number, value: string): boolean => value.length >= len

export const validateName = (value: string): string | null => {
  const NAME = /^[a-zA-Z\s]+$/

  /* check name correctness
   * must be only symbols from regular expression
   */
  if (!NAME.test(value)) {
    return 'Name should contain only letters!'
  }

  return null
}

export const validateEmail = (value: string): string | null => {
  const EMAIL = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/

  // check email for correct length
  if (!EMAIL.test(value)) {
    return 'Email is not valid!'
  }

  return null
}

export const validatePassword = (value: string): string | null => {
  // check password for correct length
  if (!minLength(6, value)) {
    return 'Password has incorrect length!'
  }

  return null
}
