import { FC } from 'react'

import { styles } from './styles'

interface ProgressPointProps {
  number: number
  progress: number
  title: string
}

export const ProgressPoint: FC<ProgressPointProps> = ({ number, title, progress }) => {
  return (
    <div css={[styles.point.base, progress >= number ? styles.point.active : null]}>
      <span css={styles.number}>0{number}</span>
      <span css={styles.title}>{title}</span>
    </div>
  )
}
