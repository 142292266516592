import { FC } from 'react'

import { styles } from './styles'
import { ProgressPoint } from '..'

interface ProgressBarProps {
  progress: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div css={styles.progress}>
      <div css={styles.points}>
        <ProgressPoint number={1} title="name" progress={progress} />
        <ProgressPoint number={2} title="genres" progress={progress} />
        <ProgressPoint number={3} title="access" progress={progress} />
      </div>
      <div css={styles.container}>
        <div
          css={[
            styles.bar.start,
            progress === 2 ? styles.bar.middle : null,
            progress === 3 ? styles.bar.final : null,
          ]}
        />
      </div>
    </div>
  )
}
